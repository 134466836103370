<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:47:07
-->
<template>
  <div id="stores">
    <div class="search">
      <van-button
        round
        size="small"
        block
        :icon="sousuo"
        type="default"
        :to="{path:'/searchbox',query:{type:'storeSearch'}}"
      >
        <span @click="trackEventInner">请输入关键词进行搜索</span>
      </van-button>
    </div>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="querystore"
        :offset="50"
        :immediate-check="false"
      >
        <StoreCart
          :storeid="item.id"
          :storename="item.name"
          :ext="item.ext"
          :description="item.description"
          :spuResList="item.itemResList"
          :storeCode="item.code"
          v-for="item in data"
          :key="item.id"
        />
      </van-list>
    </div>
    <van-loading size="24px" v-show="allstores" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import sousuo from "../../assets/icon/souuso_icon@2x.png";
import StoreCart from "./components/storeCart.vue";
import { queryTreeNode } from "./service";
import { Toast } from "vant";
import { trackEvent } from '../../utils/utils'
export default {
  components: { Search, StoreCart },
  data() {
    return {
      sousuo,
      loading: false,
      finished: false,
      data: [],
      allstores: false,
      currentPage: 1,
    };
  },
  methods: {
    trackEventInner(eventName) {
      trackEvent(null, '点击搜索栏')
    },
    getTreeNode(currentPage) {
      this.allstores = true;
      queryTreeNode({ currentPage: currentPage })
        .then((respones) => {
          if (respones.status === "0") {
            if (respones.data.items.length < 10) {
              this.finished = true;
            }
            this.allstores = false;
            this.currentPage = Number(respones.data.page);
            this.data = respones.data.items;
            this.loading = false;
          } else {
            this.loading = false;
            this.finished = true;
            Toast.fail(respones.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.finished = true;
          return err;
        });
    },
     querystore() {
      this.loading = true;
      if (!this.finished) {
        this.currentPage = this.currentPage + 1;
      }
      queryTreeNode({ currentPage: this.currentPage })
        .then((respones) => {
          if (respones.status === "0") {
            if (respones.data.items.length < 10) {
              this.finished = true;
            }
            this.loading = false;
            this.currentPage = Number(respones.data.page);
            this.data = this.data.concat(respones.data.items);
          } else {
            this.loading = false;
            this.finished = true;
            Toast.fail(respones.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.finished = true;
          return err;
        });
    },
  },
  created() {
    this.getTreeNode(1);
  },
};
</script>

<style lang="less" scoped>
#stores {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    padding: 5px 12px;
    background-image: @GradientColor;
    .van-button__text {
      color: #96979b;
    }
    .van-button--small {
      height: 32px;
      border: none;
    }

    .search-title {
      width: 32px;
      height: 44px;
      border: none;
      padding: 8px 0 8px 8px;
      .van-badge__wrapper {
        padding-top: 2px;
        .van-badge--dot {
          border-color: #ee0a24;
        }
        .van-badge--fixed {
          top: 3px;
          right: 6px;
        }
        .imgStyle {
          width: 24px;
          height: 24px;
          color: #fff;
        }
      }
    }
  }
  .content {
    flex: 1 1 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    background: #f5f6f7;
  }
}
</style>

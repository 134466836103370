<!--
 * @Autor: syq
 * @Date: 2021-07-11 15:22:57
-->
<template>
  <div class="storeCart">
    <div class="top">
      <div class="left">
        <img :src="findlogo(ext)" alt="" />
      </div>
      <div class="right">
        <div class="right-top">
          <h4>
            <span>{{ this.storename }}</span>
          </h4>
          <a @click="searchMore">进入店铺 <img :src="icon" /></a>
        </div>
        <div class="right-content">
          <p>
            {{ this.description }}
          </p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in spuResList.slice(0, 4)" :key="item.index">
          <CommodityCard
            :Productname="item.name"
            :Price="item.price"
            :mediaList="item.mediaList || []"
            :item="item"
            :storeCode="storeCode"
          />
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import CommodityCard from "../../../components/CommodityCard.vue";
import home0 from "../../../assets/icon/screening_done_icon@1x.png";
import icon from "../../../assets/icon/forward_icon_Inno@2x.png";
import { trackEvent } from '../../../utils/utils'


export default {
  components: { CommodityCard },
  data() {
    return {
      home0: home0,
      icon: icon,
    };
  },
  methods: {
    trackEventInner(eventName) {
      trackEvent(null, eventName)
    },
    findlogo(item){
      return item ? JSON.parse(JSON.parse(item).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    searchMore() {
      trackEvent(null, '进入店铺')
      this.$router.push({
        path: "/storehome",
        query: { storeCode: this.storeCode, storeid: this.storeid },
      });
    },
  },
  props: {
    storeid: {
      type: String,
    },
    ext:{
      type:String,
    },
    storename: {
      type: String, // 类型按需求
    },
    storeCode: {
      type: String,
    },
    description: {
      type: String,
    },
    spuResList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.van-grid {
  flex-wrap: nowrap;
}
.storeCart {
  width: 100%;
  margin: 0 0 8px 0;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  .top {
    width: 100%;
    display: flex;
    background-color: #fff;
    .left {
      margin-right: 8px;
      img {
        width: 32px;
        height: 32px;
        border: 1px solid #f5f6f7;
      }
    }
    .right {
      flex: 1;
      .right-top {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          flex: 1;
          margin: 0;
          span {
            display: inline-block;
            width: 213px;
            height: 20px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        a {
          width: 90px;
          text-align: right;
          color: @Prompt;
          padding: 0 12px;
          height: 18px;
          font-size: 12px;
          border-radius: 10px;

          img {
            width: 12px;
            height: 12px;
            position: relative;
            top: 1.5px;
          }
        }
      }
      .right-content {
        width: 100%;

        p {
          width: 200px;
          margin: 0;
          font-size: 12px;
          line-height: 14px;
          color: #96979b;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    min-height: 50px;
    margin-top: 16px;
    background-color: #fff;
    /deep/ .van-grid {
      .van-grid-item__content {
        padding: 8px;
      }
    }
  }
}
</style>

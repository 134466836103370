<!--
 * @Autor: syq
 * @Date: 2021-07-11 15:38:47
-->
<template>
  <div class="fourInarow-details" @click="() => shopdetails()">
    <van-image
      :src="
        (mediaList && mediaList.length && mediaList[0].url) ||
        'http://www.liby.com.cn/images/logo.png'
      "
      class="details-img"
    />
    <p class="goods-title">{{ item.name }}</p>
    <p class="goods-price">¥{{ item.price }}</p>
  </div>
</template>
<script>
import { trackEvent } from '../utils/utils'

export default {
  data() {
    return {};
  },
  methods: {
    shopdetails() {
      trackEvent(null, '点击商品')
      const { itemCode } = this.item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode: itemCode, storeCode: this.storeCode },
      });
    },
  },
  props: {
    Productname: {
      type: String, // 类型按需求
    },
    storeCode: {
      type: String,
    },
    Price: {
      type: Number,
    },
    mediaList: {
      type: Array,
    },
    item: {
      type: Object,
    },
  },
};
</script>
<style lang="less" scoped>
.fourInarow-details {
  width: 100%;
  background: #fff;
  border-radius: 0 0 8px 8px;
  .details-img {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    img {
      border-radius: 4px;
    }
  }

  .goods-title {
    margin: 4px 0 0 0;
    width: 70px;
    height: 30px;
    line-height: 16px;
    font-size: 12px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .goods-price {
    margin: 8px 0 0 0;
    width: 70px;
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    font-family: Montserrat, Montserrat-Medium;
    color: @Price;
  }

  /deep/.van-grid-item__content {
    padding: 8px;
  }
}
</style>
